import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ConnectView from '../views/Connect.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        props: { isConnectView: true, isRoomCodeView: true },
        component: ConnectView
    },
    {
        path: '/org/:id',
        name: 'orgWebConnect',
        component: ConnectView,
        props: { isOrganizationConnectView: true, isRoomCodeView: true }
    },
    {
        path: '/:roomCode',
        name: 'roomCodeConnect',
        props: { isRoomCodeConnectView: true, isRoomCodeView: true },
        component: ConnectView
    },
    {
        path: '/info/sharing',
        name: 'sharingView',
        props: { isSharingView: true },
        component: ConnectView
    },
    {
        path: '/info/download',
        name: 'downloadView',
        props: { isDownloadView: true },
        component: ConnectView
    }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router
