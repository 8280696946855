
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'DefaultInstructions',
    data: () => ({

    }),

    props: {
        downloadPath: {
            type: String,
            default: ''
        },
    },

    created() {
        this.$nextTick(()=> {
            // We need a timeout hack for this method to work on FF & Opera
            setTimeout(()=>{
                (this.$refs['link'] as any).click();
            }, 100);
        });
    }
});

