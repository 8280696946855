
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Android4Instructions',
    data: () => ({

    }),
});

