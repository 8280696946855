
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'WasmComponent',

    created() {
        window.app.senderStatusDidChange = (status: string) => {
            this.$emit('senderStatusDidChange', status);
        };

        window.app.senderDidConnect = (status: string) => {
            this.$emit('senderDidConnect', status);
        };

        window.app.senderDidDisconnect = (status: string) => {
            this.$emit('senderDidDisconnect', status);
        };

        window.app.senderConfigRetrievalFailed = () => {
            this.$emit('senderConfigRetrievalFailed');
        };

        window.app.senderConfigRetrievalSucceeded = (config: any) => {
            this.$emit('senderConfigRetrievalSucceeded', config);
        };

        window.app.senderHasError = (error: any) => {
            this.$emit('senderHasError', error);
        };

        window.app.senderNeedsPassword = (payload: any) => {
            this.$emit('senderNeedsPassword', payload);
        };

        window.app.senderFailedAllQueries = () => {
            this.$emit('senderFailedAllQueries');
        }

        window.app.senderReceiversListUpdated = (payload: any) => {
            this.$emit('senderReceiversListUpdated', payload);
        }

    }
});
