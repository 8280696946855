import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/variables.css';

// Extend window to allow our '.app' property
declare global {
    interface Window { app: any; vueAppReference: any }
}

const app = createApp(App);

// Store
app.use(store);

// Router
app.use(router);

// Directives
app.directive('focus', {
    mounted: (el) => el.focus()
});

app.mount('#app');