
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'iosInstructions',
    data: () => ({

    }),

    props: {
        binaries: {
            type: Object,
            default: null
        },
    },
});

