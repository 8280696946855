
import { defineComponent } from 'vue';
import ColorThief from 'colorthief';

const __default__ = defineComponent({
    name: 'AppView',

    data: () => ({
        config: null as any | null,
        foundRoom: null as any | null,
        brandingAccentColor: null as any | null,
        brandingOverlayColor: null as any | null,
        roomCode: '' as string,
        foundBranding: {} as any,
        colorThief: {} as ColorThief,
    }),

    methods: {
        // Returns a promise. Resolve when an image
        // stops loading to there is no pop-in.
        async preloadBrandingImage(url: string) {
            return new Promise(resolve => {
                const image = new Image();
                image.addEventListener('load', () => {
                    resolve(image);
                });
                image.src = url;
            });
        },

        onRoomCodeInput(roomCode: string) {
            this.roomCode = roomCode;
        },

        onBackgroundLoaded(){
            console.log("Room background loaded");
        },

        onLogoLoaded(){
            console.log('Room logo loaded');
        },

        roomFoundHandler(foundBranding: any) {
            this.foundBranding = foundBranding;

            // Generate color palette based on branding background
            this.$nextTick(()=> {
                const img = this.$refs['roomBackground'] as HTMLImageElement;
                const accentPaletteChoice = 1;
                const overlayPaletteChoice = 3;
                if(img) {
                    // Make sure image is finished loading
                    if (img.complete) {
                        const palette = this.colorThief.getPalette(img);
                        this.brandingAccentColor = palette[accentPaletteChoice];
                        this.brandingOverlayColor = palette[overlayPaletteChoice];
                    } else {
                        img.addEventListener('load', () => {
                            const palette = this.colorThief.getPalette(img);
                            this.brandingAccentColor = palette[accentPaletteChoice];
                            this.brandingOverlayColor = palette[overlayPaletteChoice];
                        });
                    }
                }
            });
        }
    },

    computed: {
         getBrandingBodyTextColor(){
            return this.foundBranding.textColor ? this.foundBranding.textColor : 'white';
        },

        brandingOverlayColorGradient() {
            if(!this.brandingOverlayColor) return '';

            // Build a gradient with the dominant color of the branding background
            return `linear-gradient(to top right, 
                rgba(${this.brandingOverlayColor[0]}, ${this.brandingOverlayColor[1]}, ${this.brandingOverlayColor[2]}, 0.7),
                rgba(${this.brandingOverlayColor[0]}, ${this.brandingOverlayColor[1]}, ${this.brandingOverlayColor[2]}, 0.35))`;
        }
    },
    
    created() {
        this.colorThief = new ColorThief();
    }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "d6a67c10": (_ctx.getBrandingBodyTextColor),
  "29374693": (_ctx.brandingOverlayColorGradient)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__