
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AndroidInstructions',
    data: () => ({

    }),
});

