import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a26d782"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dt-instructions" }
const _hoisted_2 = {
  class: "download__hidden-iframe",
  ref: "hiddenIframe",
  id: "hiddenIframe",
  frameborder: "0",
  "aria-hidden": "true"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  d: "M12.25 18.5V6M12.25 6L18.25 12M12.25 6L6.25 12",
  stroke: "currentColor",
  "stroke-linecap": "round",
  "stroke-linejoin": "round"
}
const _hoisted_5 = {
  key: 1,
  d: "M6 12H18.5M18.5 12L12.5 6M18.5 12L12.5 18",
  stroke: "currentColor",
  "stroke-linecap": "round",
  "stroke-linejoin": "round"
}
const _hoisted_6 = {
  key: 2,
  d: "M12.25 5.5V18M12.25 18L6.25 12M12.25 18L18.25 12",
  stroke: "currentColor",
  "stroke-linecap": "round",
  "stroke-linejoin": "round"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", _hoisted_2, null, 512),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true),
    (_ctx.downloadInstructionsComponent)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.downloadInstructionsComponent), {
          key: 1,
          downloadPath: _ctx.downloadPath
        }, null, 8, ["downloadPath"]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, {
      name: 'fade-' + _ctx.downloadArrowType + '-long',
      appear: ""
    }, {
      default: _withCtx(() => [
        (_ctx.isDownloadArrowVisible)
          ? (_openBlock(), _createElementBlock("svg", {
              key: 0,
              class: _normalizeClass(["download__icon", [{ 'download__icon--Windows': _ctx.userAgent.os.name == 'Windows', 'download__icon--refocused': _ctx.isWindowRefocused }, 'download__icon--' + _ctx.userAgent.browser.name]]),
              "stroke-width": "1.5",
              viewBox: "0 0 24 24",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              (_ctx.downloadArrowType === 'up')
                ? (_openBlock(), _createElementBlock("path", _hoisted_4))
                : (_ctx.downloadArrowType === 'right')
                  ? (_openBlock(), _createElementBlock("path", _hoisted_5))
                  : (_ctx.downloadArrowType === 'down')
                    ? (_openBlock(), _createElementBlock("path", _hoisted_6))
                    : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["name"])
  ]))
}