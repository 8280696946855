
import { defineComponent } from 'vue';

// Download Instructions
import defaultDownloadInstructions from '../components/download-templates/defaultDownloadInstructions.vue'
import chromeOSDownloadInstructions from '../components/download-templates/chromeOSDownloadInstructions.vue'
import androidDownloadInstructions from '../components/download-templates/androidDownloadInstructions.vue'
import android4DownloadInstructions from '../components/download-templates/android4DownloadInstructions.vue'
import incompatibleDownloadInstructions from '../components/download-templates/incompatibleDownloadInstructions.vue'
import iosDownloadInstructions from './download-templates/iosDownloadInstructions.vue';

export default defineComponent({
    name: 'DownloadInstructionsViews',
    data: () => ({
        deviceType: '' as string,
        connectButtonText: "Launch" as string,
        roomCodeDebounce: null as any,
        isDownloadArrowVisible: false as boolean,
        isWindowRefocused: false as boolean,
        downloadInstructionsComponent: '' as string,
        downloadPath: '' as string,
        mDNSBonjourName: '' as string,
        error: null as string | null,
        osVersionInteger: '' as any
    }),

    props: {
        userAgent: {
            type: Object,
            default: null
        },

        roomConfig: {
            type: Object,
            default: null
        }
    },

    components: {
        // Download Instructions
        defaultDownloadInstructions,
        chromeOSDownloadInstructions,
        androidDownloadInstructions,
        incompatibleDownloadInstructions,
        android4DownloadInstructions,
        iosDownloadInstructions
    },

    computed: {
        // Direction of the browser-specific download arrow
        downloadArrowType(): string {
            // Firefox note: we want to know when the window is focused
            // because that means the download popup window has been closed.
            if(this.userAgent.browser.name === "Safari" || 
               this.userAgent.browser.name === "Opera"  || 
               (this.isWindowRefocused && this.userAgent.browser.name === "Firefox")) 
            {
                return 'up'
            }
            else if (this.userAgent.browser.name === "Firefox") {
                return 'right'
            }
            else {
                return 'down'
            }
        },

        isIOS(): boolean{
            return (this.userAgent.os.name === "iOS" || this.userAgent.os.name === "Mac OS" && 'ontouchend' in document);
        }
    },

    methods: {
        showConnectionForm() {
            // -- Google analytics ----- 
            // if (typeof ga !== "undefined" && ga !== null) {
            //     ga('send', 'pageview', '/connect');
            // }

            //-- If a non-ditto binary platform, change the launch button to "Connect" -----
            if (this.isIOS || this.userAgent.os.name == "Android" || this.userAgent.os.name == "Chromium OS") {
                this.connectButtonText = "Connect";
            }
        },

        // We need to call this when we get a response from the room code query
        refreshConnectionInformation() {
            const binaryPath = this.roomConfig.binaries;
            const osName = this.userAgent.os.name;

            // Set the room name, logo, company name, text color, background, custom message, help text
            if (typeof this.roomConfig.bonjour != 'undefined' && this.roomConfig.bonjour != null) {
                this.mDNSBonjourName = this.roomConfig.bonjour;
            } else {
                this.mDNSBonjourName = this.roomConfig.roomName;
            }

            // -------------------------
            // -- One-Time downloads ---
            // -------------------------
            if (binaryPath) {
                // --- Windows ------
                if (osName == "Windows") {
                    if (this.userAgent.ua.search('WOW64') >= 0 || this.userAgent.ua.search('Win64') >= 0) { //ie and edge 64bit
                        if (binaryPath.win64_binary && binaryPath.win64_binary !== '') {
                            this.downloadPath = binaryPath.win64_binary;
                        }

                    } else {
                        if (binaryPath.win32_binary && binaryPath.win32_binary !== '') {
                            this.downloadPath = binaryPath.win32_binary;
                        }
                    }

                // --- Mac OS ------
                } else if (osName == "Mac OS" && !this.isIOS) {
                    if (binaryPath.macOS_binary && binaryPath.macOS_binary !== '') {
                        this.downloadPath = binaryPath.macOS_binary;
                    }
                }

                // -----------------
                if (typeof this.downloadPath == 'undefined' || this.downloadPath == null || this.downloadPath == '') {
                    if (this.isIOS || osName == "Android" || osName == "Chrome OS" || osName == "Chromium OS") {
                        // Show launch instructions on Connect Click
                    } else {
                        // Show error on connect click
                        this.error = "We were unable to retrieve the Ditto app for your operating system. Please contact your IT administrator for further assistance.";
                    }

                // -----------------
                } else {
                    // Download on connect click & Show instructions
                    // window.location = this.downloadPath as any;
                }
            
            // --- No Binary Path-------
            } else {
                this.error = "We were unable to retrieve the Ditto app for your operating system. Please contact your IT administrator for further assistance.";
            }

            if (osName !== 'iOS') {
                this.protocolCheck("ditto://connect?roomCode=" + this.roomConfig.roomCodeWithoutWhitespace, ()=> {
                    console.log('Ditto not installed');
                });
            }
            else {
                this.protocolCheck(this.roomConfig.roomCodeWithoutWhitespace);
            }
            // event.preventDefault ? event.preventDefault() : event.returnValue = false;
            return false;
   
        },

        protocolCheck(uri: any, failCb?: any) {
            const browserName = this.userAgent.browser.name;
            const osName = this.userAgent.os.name;
            
            function failCallback() {
                failCb && failCb();
            }

            if ((navigator as any).msLaunchUri) { //for IE and Edge in Win 8 and Win 10
                this.openUriWithMsLaunchUri(uri, failCb);

            } else {

                if (browserName == "Firefox") {
                    this.openUriUsingFirefox(uri, failCallback);

                } else if (browserName == 'Chrome' && (osName === 'Mac OS' || osName === 'Windows')) {
                    this.openUriWithTimeoutHack(uri, failCallback);

                } else if (browserName == "Edge") {
                    this.openUriWithMsLaunchUri(uri, failCallback);

                } else if (browserName == "IE") {
                    this.openUriUsingIEInOlderWindows(uri, failCallback);

                } else if (browserName == "Safari") {
                    //todo - not known what to do
                }
                else if (browserName == "Mobile Safari") {
                    // ARG - 4/10/19 - disabled until iOS app adds support in next release
                    // const urlParams = new URLSearchParams(window.location.search);
                    // const redirectParam = urlParams.get('redirect');
                    // if (!redirectParam) {
                    //     _this.openUniversalLink(window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/' + uri + '?redirect=true', failCallback); //todo add final universal link
                    // }
                    // else {
                    //     console.log('show the directions to download');
                    // }
                }
                else {
                    //not supported
                }
            }
        },

        openUriUsingFirefox(uri: any, failCb: any) {
            let iframe = this.$refs['hiddenIframe'];

            if (!iframe) {
                iframe = this._createHiddenIframe(document.body, "about:blank");
            }

            try {
                (iframe as any).contentWindow.location.href = uri;
            } catch (e: any) {
                if (e.name == "NS_ERROR_UNKNOWN_PROTOCOL") {
                    failCb();
                }
            }
        },

        openUriUsingIEInOlderWindows(uri: any, failCb: any) {

            if (this.getInternetExplorerVersion() === 10) {
                this.openUriUsingIE10InWindows7(uri, failCb);
                
            } else if (this.getInternetExplorerVersion() === 9 || this.getInternetExplorerVersion() === 11) {
                this.openUriWithHiddenFrame(uri, failCb);
            } else {
                this.openUriInNewWindowHack(uri, failCb);
            }
        },

        openUriInNewWindowHack(uri: any, failCb: any) {
            let myWindow = window.open('', '', 'width=0,height=0');
            if(myWindow) myWindow.document.write("<iframe src='" + uri + "'></iframe>");
            setTimeout(()=> {
                try {
                    if(myWindow) {
                        myWindow.location.href;
                        myWindow.setTimeout("window.close()", 1000);
                    }
                    //successCb();
                } catch (e) {
                    if(myWindow) {
                        myWindow.close();
                    }
                    failCb();
                }
            }, 1000);
        },

        getInternetExplorerVersion() {
            let rv;
            if (navigator.appName === "Microsoft Internet Explorer") {
                let ua = navigator.userAgent;
                // eslint-disable-next-line
                let re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
                if (re.exec(ua) != null)
                    rv = parseFloat(RegExp.$1);
            }
            else if (navigator.appName === "Netscape") {
                let ua = navigator.userAgent;
                // eslint-disable-next-line
                let re = new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})");
                if (re.exec(ua) != null) {
                    rv = parseFloat(RegExp.$1);
                }
            }
            return rv;
        },

        openUriUsingIE10InWindows7(uri: any, failCb: any) {
            var timeout = setTimeout(failCb, 1000);

            window.addEventListener("blur", function () {
                clearTimeout(timeout);
                //successCb();
            });

            var iframe = document.querySelector("#hiddenIframe");
            if (!iframe) {
                iframe = this._createHiddenIframe(document.body, "about:blank");
            }
            try {
                (iframe as any).contentWindow.location.href = uri;
            } catch (e) {
                failCb();
                clearTimeout(timeout);
            }
        },

        openUriWithTimeoutHack(uri: any, failCb: any) {
            var timeout = setTimeout(function () {
                failCb();
                handler.remove();
            }, 1000);

            //handle page running in an iframe (blur must be registered with top level window)
            let target = window as any;
            while (target != target.parent) {
                if(target) target = target.parent;
            }

            function onBlur() {
                clearTimeout(timeout);
                handler.remove();
            }

            let handler = this._registerEvent(target, "blur", onBlur);
            window.location = uri;
        },

        openUriWithMsLaunchUri(uri: any, failCb: any) {
            (navigator as any).msLaunchUri(uri,
                function () {
                    console.log('openUriWithMsLaunchUri');
                },
                failCb
            )
        },

        openUniversalLink(uri: any, failCb: any) {
            console.log('openUniversalLink', uri);
            try {
                window.location = uri;
            } catch (e) {
                console.log('open universal link error', e);
                //myWindow.close();
                //failCb();
            }
        },

        openUriWithHiddenFrame(uri: any, failCb: any) {
            var timeout = setTimeout(()=> {
                failCb();
                handler.remove();
            }, 1000);

            var iframe = document.querySelector("#hiddenIframe");
            if (!iframe) {
                iframe = this._createHiddenIframe(document.body, "about:blank");
            }

            var handler = this._registerEvent(window, "blur", onBlur);

            function onBlur() {
                clearTimeout(timeout);
                handler.remove();
                //successCb();
            }

            (iframe as any).contentWindow.location.href = uri;
        },

        _createHiddenIframe(target: any, uri: any) {
            let iframe = document.createElement("iframe");
            iframe.src = uri;
            iframe.id = "hiddenIframe";
            iframe.style.display = "none";
            target.appendChild(iframe);
            return iframe;
        },

        _registerEvent(target: any, eventType: any, cb: any) {
            if (target.addEventListener) {
                target.addEventListener(eventType, cb);
                return {
                    remove: function () {
                        target.removeEventListener(eventType, cb);
                    }
                };
            } else {
                target.attachEvent(eventType, cb);
                return {
                    remove: function () {
                        target.detachEvent(eventType, cb);
                    }
                };
            }
        },  
    },

    created() {
        // Returns the component name to use depending on the operating system.
        // Note: This is seperate from the broswser-specific download arrow.
        const osName = this.userAgent.os.name;
        this.deviceType = this.roomConfig.deviceType;
        this.osVersionInteger = parseInt(this.userAgent.os.version);

        // ----------------
        if (osName == "Chromium OS") {
            if (this.deviceType == 'SSP') {
                this.downloadInstructionsComponent = 'chromeOSDownloadInstructions';
            }
            else {
                this.downloadInstructionsComponent = 'incompatibleDownloadInstructions';
            }
        // ----------------
        } else if (this.isIOS) {
            if (this.deviceType == 'SSP') {
                this.downloadInstructionsComponent = "iosDownloadInstructions";
            }
            else {
                this.downloadInstructionsComponent = 'incompatibleDownloadInstructions';
            }
        // ----------------
        } else if (osName == "Android" && this.osVersionInteger >= 5) {
            if (this.deviceType == 'SSP') {
                this.downloadInstructionsComponent = "androidDownloadInstructions";
            }
            else {
                this.downloadInstructionsComponent = 'incompatibleDownloadInstructions';
            }
        // ----------------
        } else if (osName == "Android") {
            if (this.deviceType == 'Cast' || this.deviceType == 'Reflector') {
                this.downloadInstructionsComponent = 'android4DownloadInstructions';
            }
            else {
                this.downloadInstructionsComponent = 'incompatibleDownloadInstructions';
            }
        // ----------------
        } else if (osName == "Windows") {
            this.downloadInstructionsComponent = "defaultDownloadInstructions"
        // ----------------
        } else if (osName == "Mac OS" && !this.isIOS) {
            this.downloadInstructionsComponent = "defaultDownloadInstructions"
        // ----------------
        } else {
            this.downloadInstructionsComponent = 'incompatibleDownloadInstructions';
        }

        // Add a flag to know when the browser has been refocused. 
        // When the window is focused after a download is triggered,
        // the 'recent downloads' dialog will browser pop-up and we can reposition the arrow.
        window.onfocus = () => {
            this.isWindowRefocused = true;
        }; 

        // Show the one-time download arrow at a 
        // slight delay to increase the noticeablility 
        if(osName === 'Windows' || osName === 'Mac OS'){
            setTimeout(()=> {
                this.isDownloadArrowVisible = true;
            }, 500);
            // Remove arrow after a short time
            setTimeout(()=> {
                this.isDownloadArrowVisible = false;
            }, 6000);
        }

        this.refreshConnectionInformation();
    }
});
